import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import colors from 'vuetify/lib/util/colors';
import '@mdi/font/css/materialdesignicons.css'
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#00BCD4',
        secondary: '#9c27b0',
        accent: '#03a9f4',
        error: '#f44336',
        warning: '#ff9800',
        info: '#607d8b',
        success: '#8bc34a'
      },
      dark:{
        primary: '#00BCD4',
        secondary: '#9c27b0',
        accent: '#03a9f4',
        error: '#f44336',
        warning: '#ff9800',
        info: '#607d8b',
        success: '#8bc34a'
      }
    }
  },
  icons: {
    iconfont: 'mdiSvg',
  }
});
