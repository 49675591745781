<template>
  <div>
    <nav>
      <v-app-bar color="primary" clipped-left app>
        <v-app-bar-nav-icon @click="drawer=!drawer"></v-app-bar-nav-icon>
        <v-toolbar-title class="text-uppercase">
          <v-btn plain x-large @click="gotoHomepage">sakurati</v-btn>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <CalendarComponent v-if="isLoggedIn===true"></CalendarComponent>
        <v-btn icon @click="gotoDownloadsPage">
          <v-icon > mdi-download </v-icon>
        </v-btn>
        <v-divider vertical class="mx-4"></v-divider>
        <v-switch
          class="mt-5"
          v-model=isDarkMode
          inset
          color="grey darken-3"
          append-icon="mdi-theme-light-dark"
          @click="setDarkMode"
        ></v-switch>
      </v-app-bar>
      <v-navigation-drawer v-model="drawer" clipped app >
        <v-list flat nav dense >
          <v-list-group
            v-for="item in links"
            :key="item.book"
            v-model="item.active"
            prepend-icon="mdi-book"
          >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.book"></v-list-item-title>
            </v-list-item-content>
          </template>
            <v-list-item
              v-for="child in item.notes"
              :key="child.note_name"
              link 
              router 
              :to="child.route"
            > 
              <v-list-item-icon><v-icon>mdi-square-rounded-outline</v-icon></v-list-item-icon>
              <v-list-item-title v-text="child.note_name"></v-list-item-title>
            </v-list-item>

            <v-list-item  v-if="isLoggedIn===true" link @click="addANote(item)">
              <v-list-item-icon>
                <v-icon color="primary">mdi-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content >
                <v-list-item-title class="text--blue">Add note</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item  v-if="isLoggedIn===true && item.notes.length == 0" link @click="emitDeleteBook(item)">
              <v-list-item-icon>
                <v-icon color="error">mdi-delete-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content >
                <v-list-item-title class="text--blue">Delete book</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider :key="`divider-${item}`"></v-divider>
          </v-list-group>
        
          <v-list-item  v-if="isLoggedIn===true" link @click="addABook">
            <v-list-item-icon>
              <v-icon color="success">mdi-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content >
              <v-list-item-title class="text--blue">Add book</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>        
      </v-navigation-drawer>
    </nav>

    <v-footer dark padless app>
      <v-card class="flex" flat tile>
        <v-card-text class="py-2 white--text text-center">
          <strong>{{ new Date().getFullYear() }} - sakurati</strong>
          <v-btn v-if="isLoggedIn===false" dark icon absolute right x-small @click="isOpenCreds=true">
            <v-icon > mdi-cube-scan </v-icon>            
          </v-btn>
          <v-btn v-else dark icon absolute right x-small @click="logout">
            <v-icon> mdi-logout </v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-footer>

    <!-- Login creds sheet -->
    <v-bottom-sheet inset v-model="isOpenCreds" width="400px">
      <v-sheet class="text-center" height="280px" >
        <div class="ma-4 pa-4">
          <v-form ref="credsForm" >
            <div class="text-h4 grey--text mb-2">Enter creds</div>
            <v-text-field
              v-model="username"
              :rules="[nameRules.required, nameRules.max]"
              label="Username"
              required
              maxlength="64"
              counter
            ></v-text-field>
            <v-text-field
              v-model="password"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass ? 'text' : 'password'"
              :rules="[nameRules.required, nameRules.max]"
              label="Password"
              required
              counter
              maxlength="64"
              @click:append="showPass = !showPass"
            ></v-text-field>
            <v-btn class="mt-6" text color="primary" @click="enterCreds">Login</v-btn>
          </v-form>
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <CreateBook></CreateBook>
    <CreateNote></CreateNote>
    <Toast ref="toast"></Toast>

  </div>
</template>

<script>
  import { eventBus } from "../main";
  import CreateBook from '@/components/CreateBook'
  import CreateNote from '@/components/CreateNote'
  import CalendarComponent from '@/components/CalendarComponent'
  import Toast from '@/components/Toast'
  export default {
    components: {
      CreateBook,
      CreateNote,
      CalendarComponent,
      Toast
    },
    data() {
      return {
        drawer: true,
        links: [],
        isDarkMode: false,
        isOpenCreds:false,
        showPass: false,
        nameRules: {
          required: value => !!value || 'Required.',
          max: v => v.length <= 64 || 'Max 64 characters',
        },
        username:'',
        password:'',
        isLoggedIn: false,
        timeoutVar:null,
        deleteBookDialog:false,
        deleteBookName: '',
      }
    },
    methods: {
      loadNotebooks(){
        var url = this.$webUrl+"/api/notes/all"
        fetch(url)
          .then(async response => {
            const data = await response.json();
            if (!response.ok) {
              // error
              const error = (data && data.message) || response.statusText;              
              console.error(error)
              return Promise.reject(error);
            }
            //success            
            this.links = data.all_books
            eventBus.$emit("emitNotes", this.links)
          })
          .catch(error => {
            console.error("There was an error!", error);
          });
      },
      gotoHomepage(){
        if (this.$route.path != '/'){
          this.$router.push('/')
        }
      },
      gotoDownloadsPage(){
        if (this.$route.path != '/downloads'){
          this.$router.push('/downloads')
        }
      },
      setDarkMode(){
        // "$vuetify.theme.dark"
        this.$vuetify.theme.dark = this.isDarkMode
        this.saveThemeToLocalStorage()
      },
      loadThemeFromLocalStorage(){
        var tempDark = localStorage.getItem('darkMode') 
        if (tempDark == null){
          tempDark = false
        }
        this.isDarkMode = (tempDark === 'true')
        this.$vuetify.theme.dark = this.isDarkMode        
      },
      saveThemeToLocalStorage(){
        localStorage.setItem('darkMode', this.isDarkMode)
      },
      addANote(item){
        eventBus.$emit("openCreateNoteDialog", item.book)
      },
      addABook(){
        eventBus.$emit("openCreateBookDialog")
      },
      enterCreds(){
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic '+ btoa(this.username + ":" + this.password)
          }
        }
        var url = this.$webUrl+"/api/login"
        fetch(url, requestOptions)
          .then(async response => {
            const data = await response.json();
            if (!response.ok) {
              // error
              const error = (data && data.message) || response.statusText;              
              return Promise.reject(error);
            }
            //success
            this.$root.toast.show({message: 'Logged in 🥳', color: 'success'})
            this.loggedIn(data)           
          })
          .catch(error => {
            this.$root.toast.show({message: 'Error logging in 😱 !', color: 'error'})
            console.error("[ERROR] There was an error logging in.!", error);
          });
      },
      loggedIn(data){
        this.username = ''
        this.password = ''
        this.$refs.credsForm.resetValidation()
        this.isLoggedIn = true
        this.isOpenCreds= false
        this.$token.value = data.Authorization // TODO: this will change based on header
        var timeout = this.getRefreshTime(data.Authorization)
        this.timeoutVar = setTimeout(this.refreshToken, timeout*1000)
        eventBus.$emit("gotToken")        
      },
      logout(){
        // invalidate token and clear refresh token
        clearTimeout(this.timeoutVar)
        this.$token.value = ''
        this.isLoggedIn = false
        eventBus.$emit("gotToken")
        this.$root.toast.show({message: 'Logged out 👋', color: 'successs'})
      },
      getRefreshTime(jwtToken){
        var token = jwtToken.split(' ')[1]
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        var decodedToken = JSON.parse(jsonPayload)
        var refreshTime = decodedToken['exp']
        var currentTime = Math.floor(Date.now()/1000)
        var timeout = refreshTime - currentTime - 5        
        return timeout
      },
      refreshToken(){
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.$token.value
          }
        }
        var url = this.$webUrl+"/api/login/refresh"
        fetch(url, requestOptions)
          .then(async response => {
            const data = await response.json();
            if (!response.ok) {
              // error
              const error = (data && data.message) || response.statusText;              
              return Promise.reject(error);
            }
            //success
            this.loggedIn(data)
          })
          .catch(error => {
            this.$root.toast.show({message: 'Error refreshing token 😱 !', color: 'error'})
            console.error("[ERROR] There was an error refreshing token!", error);
            this.logout()
          });
      },
      loadAllBooks(){
        this.loadNotebooks()
      },
      openBookTab(book){
        var that = this;
        setTimeout(function() { 
          for (let i = 0; i < that.links.length; i++) {
            if (that.links[i]['book'] == book){
              that.links[i].active = true
            }
          }  
        }, 1000); // 1s delay to open side bar
      },      
      emitDeleteBook(item){
        eventBus.$emit('openDeleteBookDialog', item.book)
      }
    },
    computed:{      
    },
    mounted(){
      // Code is run after the component is created.
      this.loadThemeFromLocalStorage()      
      this.loadNotebooks();
      this.$root.toast = this.$refs.toast
    },
    created(){
      eventBus.$on('refreshAllBooksAndNotes', this.loadAllBooks)
      eventBus.$on('openBookTab', this.openBookTab)
    },
    beforeDestroy(){
      eventBus.$off('refreshAllBooksAndNotes', this.loadAllBooks)
      eventBus.$off('openBookTab', this.openBookTab)
    }
  }
</script>

