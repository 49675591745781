<template>
  <v-snackbar 
    min-width="100px"
    max-width="300px"
    :color="color"
    :timeout="timer"
    transition="fade-transition"
    v-model="showSnackbar"
    top text>
    {{message}}
  </v-snackbar>
</template>
  
<script>
export default {
  name: "Toast",
  data() {
    return{
      showSnackbar: false,
      message: '',
      color: 'success',
      timer: 3000
    }
  },
  methods:{
    show(data) {
      this.message = data.message || 'missing "message".'
      this.color = data.color || 'success'
      this.timer = data.timer || 1500
      // this.icon = data.icon || 'mdi-check'
      this.showSnackbar = true
      // this.$root.toast.show({message: 'Error logging in 😱 !', color: 'error'})
    }
  }
}
</script>
  