import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {path: '/', name: 'Home', component: Home},
  {path: '/3d', name: '3D', component: () => import('../views/3D.vue')},
  {path: '/converter', name: 'Converter', component: () => import('../views/Converter.vue')},
  {path: '/crypto', name: 'Crypto', component: () => import('../views/Crypto.vue')},
  {path: '/images', name: 'Images', component: () => import('../views/Images.vue')},
  {path: '/cal/:id', name: 'Calendar', component: () => import('../views/Calendar.vue')},
  {path: '/note/:id', name: 'Notes', component: () => import('../views/Notes.vue')},
  {path: '/downloads', name: 'Downloads', component: () => import('../views/Downloads.vue')},
  {path: '*', name: 'Not Found', component: () => import('../views/PageNotFound.vue')},
  // {path: '*', redirect: '/404'},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
