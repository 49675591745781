<template>
  <div class='home'>
    <h1 class="subheading">Welcome to Sakurati !!</h1>
    <p>I just keep a lot of my frequently used notes here. Feel free to browse them.</p>
    
    <v-card elevation="0">
      <v-card-title> 
        Contents
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="notebook_headers"
        :items="notebooks"
        :search="search"
        :items-per-page="50"
        :footer-props="{'items-per-page-options':[10, 50, 100, -1]}"
        :loading="isLoading"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon color="primary" class="mr-2" @click="rowClicked(item)">mdi-chevron-right-box</v-icon>
        </template>
      
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'Home',
    components: {},
    data() {
      return {
        isLoading : false,
        search: '',
        notebook_headers:[
          {text: 'Book', align: 'start', filterable: true, value:'book'},
          {text: 'Note', align: 'start', filterable: true, value:'note_name'},
          {text: 'Created on', align: 'start', filterable: true, value:'created_on'},
          {text: 'Last Updated on ', align: 'start', filterable: true, value:'updated_on'},
          {text: 'Go', value: 'actions', sortable: false },
        ],
        notebooks: []
      }
    },
    methods:{
      rowClicked(value){
        this.$router.push({ path: value.route })
      },      
      setHomePage(){
        var url = this.$webUrl+"/api/notes/all"
        fetch(url).then(async response => {
          const data = await response.json();
          if (!response.ok) {
            // error
            const error = (data && data.message) || response.statusText;              
            console.error(error)
            return Promise.reject(error);
          }
          //success            
          var linkData = data.all_books
          var notes = []
          for(var i=0;i<linkData.length;i++){
            notes.push.apply(notes, linkData[i].notes)
          }
          this.notebooks = notes
        }).catch(error => {
          console.error("There was an error!", error);
        });
      }
    },
    mounted(){
      this.setHomePage()
    }
  }
</script>
