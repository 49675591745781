<template>
  <v-menu 
    v-model="menu"
    transition="fade-transition" 
    nudge-left="120" 
    offset-y min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon text x-large v-bind="attrs" v-on="on">
        <v-icon>mdi-calendar-clock-outline</v-icon>
      </v-btn>
    </template>
    <v-date-picker
      v-model="date"            
      max="2050-12-31"
      min="1950-01-01"
      first-day-of-week="1"
      @change="gotoCalendar"
      scrollable
    ></v-date-picker>
  </v-menu>
</template>

<script>
  export default {
    components: {
    },
    data() {
      return {
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
      }
    },
    methods:{
      gotoCalendar(){
        var d = this.date.split('-').join('')
        this.$router.push('/cal/'+d)
      },
    },
  }
  
</script>