<template>
  <div>
     <!-- create dialog -->
     <v-dialog v-model="noteDialog" persistent width="300">
      <v-card>
        <v-card-title class="text-h5 primary--text">
          Create a note
          <v-spacer></v-spacer>
          <v-btn small fab icon @click="closeCreateNoteDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>        
        <v-text-field counter maxlength="30" v-model="noteName" label="Note name" class="mx-4 px-4 "></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="createNote">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { eventBus } from "../main";
  export default {
    data () {
      return {
        noteDialog:false,
        noteName: '',
        bookName: '',
      }
    },
    methods:{
      openCreateNoteDialog(book){
        this.noteDialog = true
        this.bookName = book
      },
      createNote(){
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.$token.value
          },
          body: JSON.stringify({
            name: this.noteName,
            content: '# '+this.noteName+'\n',
            book: this.bookName
          })
        }
        var url = this.$webUrl+"/api/notes/"
        fetch(url, requestOptions)
          .then(async response => {
            const data = await response.json();
            if (!response.ok) {
              // error
              const error = (data && data.message) || response.statusText;              
              return Promise.reject(error);
            }
            //success
            this.closeCreateNoteDialog()
            eventBus.$emit('refreshAllBooksAndNotes')
            this.$root.toast.show({message: 'Note created. 👍', color: 'success'})
            this.$router.push({ path: '/note/'+data.id })
          })
          .catch(error => {
            this.closeCreateNoteDialog()
            eventBus.$emit('refreshAllBooksAndNotes')
            console.log('error', error)
            this.$root.toast.show({message: 'Error creating note', color:'warning'})
            console.log('[ERROR]', error)
          });
      },
      closeCreateNoteDialog(){
        this.noteDialog =false
        this.noteName= ''
      },
    },
    created() {
      eventBus.$on('openCreateNoteDialog', this.openCreateNoteDialog);
    }
  }

</script>