<template>
  <div>
    <v-dialog v-model="bookDialog" persistent width="300">
      <v-card>
        <v-card-title class="text-h5 primary--text">
          Create a book
          <v-spacer></v-spacer>
          <v-btn small fab icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        
        <v-text-field counter maxlength="20" v-model="bookName" label="Book name" class="mx-4 px-4 "></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="createBook">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteBookDialog" persistent width="600">
      <v-card>
        <v-card-title class="text-h5 warning--text">
          Are you sure you want to delete book {{ bookName }} ?
          <v-spacer></v-spacer>
          <v-btn small fab icon @click="closeDeleteBookDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="deleteBook">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { eventBus } from "../main";
  export default {
    data () {
      return {
        bookDialog: false,
        bookName:'',
        deleteBookDialog:false,
      }
    },
    methods:{
      openDialog(){
        this.bookDialog = true
      },
      closeDialog(){
        this.bookName = ''
        this.bookDialog = false
      },
      createBook(){
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.$token.value
          },
          body: JSON.stringify({name: this.bookName})
        }
        var url = this.$webUrl+"/api/books"
        fetch(url, requestOptions)
          .then(async response => {
            const data = await response.json();
            if (!response.ok) {
              // error
              const error = (data && data.message) || response.statusText;    
              console.log(response.statusText)              
              return Promise.reject(error);
            }
            //success
            this.$root.toast.show({message: 'Created book 👍', color: 'success'})
            this.closeDialog()
            eventBus.$emit("refreshAllBooksAndNotes")
          })
          .catch(error => {
            this.$root.toast.show({message: 'Error creating book 😱', color: 'error'})
            console.error("[ERROR] There was an error logging in.!", error);
            this.closeDialog()
          });
        
      },
      deleteBookDialogBox(book){
        this.deleteBookDialog = true
        this.bookName = book
      },
      closeDeleteBookDialog(){
        this.deleteBookDialog = false
        this.bookName = ''
      },
      deleteBook(){
        const requestOptions = {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.$token.value
          }
        }
        var url = this.$webUrl+"/api/books/"+this.bookName
        fetch(url, requestOptions)
          .then(async response => {
            const data = await response.json();
            this.closeDeleteBookDialog()
            if (!response.ok) {
              // error
              const error = (data && data.message) || response.statusText;  
              return Promise.reject(error);
            }
            //success
            this.$root.toast.show({message: 'Book deleted 👍', color: 'success'})
            eventBus.$emit('refreshAllBooksAndNotes')
            this.$router.push('/')
          })
          .catch(error => {
            this.$root.toast.show({message: 'Error deleting book. 😱', color: 'error'})
            console.error("[ERROR] There was an error deleting book!", error);
          });
      },
    },
    created() {
      eventBus.$on('openCreateBookDialog', this.openDialog)
      eventBus.$on('openDeleteBookDialog', this.deleteBookDialogBox)
    }
  }
</script>